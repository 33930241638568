
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
import { request, closeWebView } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "DrawMoneyList",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectSuccess extends Vue {
  private async handleBuy() {
    closeWebView();
  }
  private getStatusText(status: number) {
    if (status === 1) {
      return this.$t("yitiajiao");
    } else if (status === 2) {
      return this.$t("yichujin");
    } else if (status === 3) {
      return this.$t("yishenhe");
    } else if (status === -1) {
      return this.$t("shenheshibai");
    } else if (status === -2) {
      return this.$t("fukuanshibai");
    }
  }
  private orderList: any = [
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 1,
    // },
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 2,
    // },
    // {
    //   money: 300,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 3,
    // },
    // {
    //   money: 400,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -1,
    // },
    // {
    //   money: 500,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -2,
    // },
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 1,
    // },
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 2,
    // },
    // {
    //   money: 300,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 3,
    // },
    // {
    //   money: 400,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -1,
    // },
    // {
    //   money: 500,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -2,
    // },
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 1,
    // },
    // {
    //   money: 100,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 2,
    // },
    // {
    //   money: 300,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: 3,
    // },
    // {
    //   money: 400,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -1,
    // },
    // {
    //   money: 500,
    //   orderNo: 12121212,
    //   applyTime: 1678713949,
    //   status: -2,
    // },
  ];
  // 询我的提款列表-近6个月
  private async getOrderList() {
    let result: any = await request({
      method: "GET",
      path: "/v1/api/user/draw/list",
    });
    this.orderList = result;
  }
  private getExactTime(time: number) {
    var date = new Date(time * 1000);
    var year = date.getFullYear();
    var month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var dates = date.getDate() + "";
    var hour = date.getHours() + ":";
    var min = date.getMinutes() + ":";
    var second = date.getSeconds();
    return dates + "/" + month + "/" + year + " " + hour + min + second;
  }
  mounted() {
    setTimeout(() => {
      this.getOrderList();
    }, 100);
  }
}
